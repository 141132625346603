import allCountriesAndStates from "../__mocks__/countries.json";

const Countries = JSON.parse(JSON.stringify(allCountriesAndStates));

export const countries = Countries.map((country, id) => ({
    name: country.name,
    value: country.code2,
    id: id + 1,
}));

export const countriesAndStates = Countries;
